<template>
	<div class="home-wrapper" :class="{ initial: !firstLoadTriggered }">
		<loading-spinner v-if="loading.companies" />
		<div class="home">
			<div class="home-head flex" v-if="!firstLoadTriggered">
				<div class="top-bar">
					<div class="user" v-if="connection">
						{{localization.t('home.filter-message')}} <div class="btn" @click="handleImportModalOpen">{{localization.t('home.import-convert')}}</div> {{localization.t('home.vat-file')}}
					</div>
					<div class="user" v-if="!connection">
						{{localization.t('app.no-connection')}}. <div class="btn" @click="emit('onOpenConnections')">{{localization.t('ho-modal-connect.select-connection')}}</div>
					</div>
				</div>
			</div>

			<div class="home-grid" v-if="connection && connection.active">
				<div class="filters-container">
					<home-filters
						:loading="loading"
						:filterFields="filterFieldsConfig"
						:filters="listOptions.filters"
						@onFiltersChange="handleChangeFilters"
						@onApplyFilters="handleApplyFilters"
					/>
				</div>

				<div class="grid-container">
					<grid
						v-if="firstLoadTriggered"
						:loading="loading"
						:config="gridConfig"
						:data="data.data"
						:total="data.total"
						:listOptions="listOptions"
						:hasDataErrors="dataHasInvalidFields"
						:isSelectAllMode="isSelectAllMode"
						:selections="selections"
						:rowKeysConfig="initRowKeysConfig"
						@onChangePage="handleChangePage"
						@onChangeFilters="handleChangeFilters"
						@onChangeSorting="handleChangeSorting"
						@onChangeSelection="handleChangeSelection"
						@onChangeSelectAllMode="handleChangeSelectAllMode"
						@onResetSelection="handleResetSelections"
					>
						<template v-slot:topBarPlaceholder>
							<export-actions
								:loading="loading.export"
								:hasDataErrors="dataHasInvalidFields"
								:exportButtonsDisabled="exportButtonsDisabled"
								:exportViesButtonDisabled="exportViesButtonDisabled"
								@onExportClick="handleExportData"
								@onExportViesClick="handleViesModalOpen"
							/>
						</template>
						<template v-slot:emptyGridResults>
							<p>{{ localization.t('grid.export-empty-results') }}</p>
							<export-actions
								:loading="loading.export"
								:hasDataErrors="dataHasInvalidFields"
								:exportButtonsDisabled="exportButtonsDisabled"
								:exportViesVisible="false"
								@onExportClick="handleExportData"
							/>
						</template>
					</grid>
				</div>
			</div>

			<export-vies-declaration
				v-if="viesModalOpen"
				:profileData="profileData"
				@onExportModalClose="handleViesModalClose"
			/>
		</div>
		
	</div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, toRaw, watch, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import { createToast } from 'mosha-vue-toastify';
import { TOAST_TYPES } from '@/constants/toastr';
import { ApiError } from "@/types/errorTypes";
import { getPaged, exportData, InvoiceLedger } from "../services/invoicesApi";
import HomeFilters from "@/components/home/filters.vue";
import ExportViesDeclaration from '@/components/home/export-vies-declaration.vue';
import ExportActions from '@/components/home/export-actions.vue';
import grid from '@/components/grid/grid.vue';
import IGridConfig from '@/components/grid/interfaces/iGridConfig'
import IListOptions from "@/interfaces/invoices/iListOptions";
import IListFilter from "@/interfaces/invoices/iListFilter";
import IFilterField from '@/interfaces/home/iFilterField';
import IInvoice from '@/interfaces/invoices/iInvoice';
import IProfile from '@/interfaces/users/iProfile';
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';
import IExportType from '@/interfaces/invoices/iExportType';
import IUser from '@/interfaces/users/iUser';
import { getSession } from '@/services/authService';
import LoadingSpinner from "@/components/loading-spinner.vue";
import router from '@/router';
import ICompany from '@/interfaces/companies/iCompany';
import IConnection from '@/interfaces/users/iConnection';

const localization = useI18n();

type LoadingFields = 'grid' | 'export' | 'companies' | 'import' | 'user' | 'connection';

const requiredFields = ['customerVat', 'customerCountry', 'nraDocType', 'number'];

const getInitalListOptions = (): IListOptions => {
	const now = new Date();

	const initListOptions: IListOptions = {
		pageIndex: 0,
		pageSize: 20,
		filters: [
			{
				field: 'ledger',
				value: InvoiceLedger.SALES
			},
			{
				field: 'startDate',
				value: startOfMonth(subMonths(now, 1))
			},
			{
				field: 'endDate',
				value: endOfMonth(subMonths(now, 1))
			},
			{
				field: 'includeCancelled',
				value: false
			}
		]
	};

	return initListOptions;
}

const getInitalLedgerOptions = () => {
	return [
		{
			label: localization.t('home.sales'),
			value: 'sales'
		},
		{
			label: localization.t('home.purchases'),
			value: 'purchases'
		}
	]
}

const initFilterFields: IFilterField[] = [
	{
		name: 'ledger',
		placeholderText: localization.t('home.ledger-placeholder'),
		initialValue: 'sales',
		canClear: false,
		options: getInitalLedgerOptions(),
		type: 'select'
	},
	{
		name: 'date',
		type: 'dateRange'
	},
	{
		name: 'company',
		initialValue: null,
		placeholderText: localization.t('home.company-placeholder'),
		options: [],
		canClear: false,
		type: 'select'
	},
];

const getInitialRowKeysConfig = (): {
	key: string,
	label?: string,
	class?: string,
	width?: number | string,
	minWidth?: number
}[] => {
	return [
			{
				key: 'nraDocType',
				width: 120,
				minWidth: 120,
				label: localization.t('grid.labels.nra-doc-type')
			},
			{
				key: 'number',
				width: 120,
				minWidth: 120,
				label: localization.t('grid.labels.number')
			},
			{
				key: 'invoiceDate',
				width: 140,
				minWidth: 140,
				label: localization.t('grid.labels.invoice-date')
			},
			{
				key: 'date',
				width: 140,
				minWidth: 140,
				label: localization.t('grid.labels.date')
			},
			{
				key: 'customerVat',
				width: 160,
				minWidth: 160,
				label: localization.t('grid.labels.customer-vat')
			},
			{
				key: 'customer',
				width: "10%",
				minWidth: 200,
				label: localization.t('grid.labels.customer')
			},
			{
				key: 'customerCountry',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.customer-country')
			},
			{
				key: 'taxBase',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.tax-base'),
				class: 'text-right ho-cell-number'
			},
			{
				key: 'tax',
				width: 100,
				minWidth: 100,
				label: localization.t('grid.labels.tax'),
				class: 'text-right ho-cell-number'
			},
			{
				key: 'status',
				width: 80,
				minWidth: 80,
				label: localization.t('grid.labels.status')
			}
		]
}

const validateInvoicesRequiredFields = (invoices: IInvoice[]) => {
	let hasInvalidRequiredValue = false;
	for (let i = 0; i < invoices.length; i++) {
		const invoice = invoices[i];
		const keys = Object.keys(invoice);
		keys.forEach(k => {
			if (requiredFields.includes(k) && !(invoice as {[key:string]: any})[k]) {
				hasInvalidRequiredValue = true;
			}
		});

		if (hasInvalidRequiredValue) {
			break;
		}
	}

	return hasInvalidRequiredValue;
}

const props = defineProps({
	user: {
		type: Object as () => IUser
	},
	companies: {
		type: Array as () => ICompany[]
	},
	connection: {
		type: Object as () => IConnection,
	}
});
const emit = defineEmits(['onImportModalOpen', 'onFirstFilterTriggered', 'onOpenConnections']);

const companies = ref([] as {
	label?: string,
	value: number
}[]);

const data = ref({
	data: [],
	total: 0
});
const filterFieldsConfig: Ref<IFilterField[]> = ref(initFilterFields);
const loading = ref({
	grid: false,
	export: false,
	companies: false,
	import: false,
	user: false,
	connection: false
});
const viesModalOpen = ref(false);

const firstLoadTriggered = ref(false);
const dataHasInvalidFields = ref(false);

const selections = ref({
	itemIds: [] as string[],
	excludedItemIds: [] as string[]
});

const isSelectAllMode = ref(false);

const initRowKeysConfig = ref(getInitialRowKeysConfig());

const initalListOptions = getInitalListOptions();

watch(() => localization.locale.value, () => {
	initRowKeysConfig.value = getInitialRowKeysConfig();
	
	filterFieldsConfig.value.forEach(filter => {
		if (filter.name === 'ledger') {
			filter.options = getInitalLedgerOptions();
		}
	})
});

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile & IDeclarantExtended);
});

const exportButtonsDisabled = computed(() => {
	return loading.value['export']
		|| loading.value['grid']
		|| dataHasInvalidFields.value;
});

const exportViesButtonDisabled = computed(() => {
	let isPurchasesOn = false;
	const foundFilter = listOptions.filters?.find(f => f.field === 'ledger');
	if (foundFilter && foundFilter.value === 'purchases') {
		isPurchasesOn = true;
	}

	return isPurchasesOn;
});

const userDataUnwatch = watch(() => props.user, (newUserData, oldUserData) => {
	const sessionData = getSession();
	const hasSession = sessionData && !Number.isNaN(sessionData.session_id);

	if (!oldUserData?.userName && newUserData?.userName) {
		if (hasSession) {
			userDataUnwatch();
		}
	}
});

const companiesDataUnwatch = watch(() => props.companies, (newData, oldData) => {
	if ((!oldData || oldData.length === 0) && (newData && newData.length > 0)) {
		const companiesFilter = filterFieldsConfig.value.find(f => f.name === 'company');
		
		if (companiesFilter) {
			companiesFilter.options = (newData || []).map(c => {
				return {
					label: c.name,
					value: c.id
				}
			});

			companies.value = companiesFilter.options;

			(listOptions.filters || []).push({
				field: 'company',
				value: companiesFilter.options[0].value
			});

			companiesFilter.initialValue = companiesFilter.options.length === 1 ? companiesFilter.options[0].value : null
		}

		companiesDataUnwatch();
	}
});

const gridConfig: IGridConfig = {
	sortFields: [
		{
			field: 'invoiceDate'
		},
		{
			field: 'date'
		},
		{
			field: 'customer'
		},
		{
			field: 'number'
		}
	]
};

initalListOptions.searchFields = [
	{
		label: 'customer',
		value: 'customer'
	},
	{
		label: 'baz',
		value: 'baz'
	},
	{
		label: 'FOO',
		value: 'foo'
	}
];

const listOptions: IListOptions = reactive(initalListOptions);

const handleChangeSelection = (newSelections: string[]) => {
	if (!isSelectAllMode.value) {
		selections.value.itemIds = newSelections;
	} else {
		selections.value.excludedItemIds = newSelections;
	}
}

const handleResetSelections = () => {
	selections.value.itemIds = [];
	selections.value.excludedItemIds = [];
}

const handleChangeSelectAllMode = (newMode: boolean) => {
	isSelectAllMode.value = newMode;
}

const handleChangePage = async (page: number) => {
	listOptions.pageIndex = page;

	const resultData = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});

	const hasInvalidFields = validateInvoicesRequiredFields(resultData.data);
	if (!dataHasInvalidFields.value && hasInvalidFields) {
		dataHasInvalidFields.value = hasInvalidFields;
	}

	data.value = resultData;
}

const handleChangeFilters = async (filters?: IListFilter[]) => {
	(filters || []).forEach(filter => {
		if (!listOptions.filters) {
			listOptions.filters = [];
		}

		const foundFilter = listOptions.filters.find(f => f.field === filter.field);
		if (foundFilter) {
			foundFilter.value = filter.value
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value
			}

			listOptions.filters?.push(newFilter);
		}
	});
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;
}

const handleChangeSorting = async (field: string, direction: "asc" | "desc") => {
	listOptions.sort = {
		field,
		direction
	}
	listOptions.pageIndex = 0;

	data.value = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});
}

const handleExportData = async (
	exportType: IExportType,
	declarantData?: IProfile & IDeclarantExtended
) => {
	const exportItemIds = isSelectAllMode.value ? selections.value.excludedItemIds : selections.value.itemIds;

	await executeAsync('export', async () => {
		return exportData(exportItemIds, isSelectAllMode.value, exportType, listOptions, declarantData);
	});
}

const handleApplyFilters = async () => {
	listOptions.pageIndex = 0;
	listOptions.sort = undefined;

	firstLoadTriggered.value = true;
	emit('onFirstFilterTriggered');
	if (dataHasInvalidFields.value !== false) {
		dataHasInvalidFields.value = false;
	}

	const resultData = await executeAsync('grid', async () => {
		return await getPaged(listOptions);
	});

	const hasInvalidFields = validateInvoicesRequiredFields(resultData.data);
	if (hasInvalidFields) {
		dataHasInvalidFields.value = hasInvalidFields;
	}

	data.value = resultData;
}

const handleViesModalOpen = () => {
	viesModalOpen.value = true;
}

const handleViesModalClose = (declarantData?: IProfile & IDeclarantExtended) => {
	if (declarantData) {
		const exportType: IExportType = {
			fileType: 'TXT',
			includeVies: true
		}
		handleExportData(exportType, declarantData);
	}
	viesModalOpen.value = !viesModalOpen.value;
}

const handleImportModalOpen = () => {
	emit('onImportModalOpen');
}

// TODO extract in HoC:
const executeAsync = async (loadingField: LoadingFields, exec: any, showToast = true) => {
	loading.value[loadingField] = true;

	try {
		const result = await exec();
		loading.value[loadingField] = false;
		return result;
	} catch (error) {
		const err = error as ApiError;
		if (err.statusCode === 401) {
			router.push('/login');
		} else {
			showToast && createToast(err.message, TOAST_TYPES.ERROR);

			if (process.env.NODE_ENV !== 'production' && showToast && err.innerMessage) {
				createToast(err.innerMessage, TOAST_TYPES.ERROR);
			}

			throw error;
		}
	} finally {
		loading.value[loadingField] = false;
	}
}
</script>

<style lang="postcss">
.home-wrapper {
	height: 100%;
}

.home-wrapper.initial {
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-wrapper.initial .home {
	height: auto;
	transition: height 0.5s ease-out
}

.top-bar {
	padding: 16px 24px 8px;
	font-size: 1.8rem;
	font-weight: 400;
}

.top-bar strong {
	font-weight: 800;
}

.home { height: 100%; }

.home-head { height: 60px; font-size: 1.6rem; }
.home-head .btn { font-size: 1.4rem; padding: 4px 8px; }

.home-grid { 
	height: 100%;
	padding: 16px;
}

.grid-container { position: relative; z-index: 0; height: 100%; }
.filters-container { position: relative; z-index: 1; }

@media (max-height: 767px) {
	.home-wrapper.initial {
		position: absolute;
		width: 100%;
	}
	
	.home-grid { 
		height: 100%;
		padding: 8px;
	}
}

</style>