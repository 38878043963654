import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ho-date-picker" }

import { ref, watch } from 'vue';
import DatePicker from 'vue3-datepicker';
import { CalendarIcon, ChevronRightIcon } from '@heroicons/vue/outline';
import IDateRangeValues from './interfaces/iDateRangeValues';


export default /*@__PURE__*/_defineComponent({
  __name: 'date-range',
  props: {
	startDate: Date,
	endDate: Date
},
  emits: ["onChange"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const currStartDate = ref(props.startDate);
const currEndDate = ref(props.endDate);

watch(() => [currStartDate.value, currEndDate.value], () => {
	valuesChanged()
});

const valuesChanged = () => {
	const values: IDateRangeValues = {
		startDate: currStartDate.value,
		endDate: currEndDate.value
	};
	emit("onChange", values)
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, [
      _createVNode(_unref(CalendarIcon), { class: "h-6 w-6" })
    ]),
    _createVNode(_unref(DatePicker), {
      class: "ho-date-input",
      modelValue: currStartDate.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((currStartDate).value = $event)),
      upperLimit: currEndDate.value
    }, null, 8, ["modelValue", "upperLimit"]),
    _createElementVNode("label", null, [
      _createVNode(_unref(ChevronRightIcon), { class: "w-8" })
    ]),
    _createVNode(_unref(DatePicker), {
      class: "ho-date-input border-l-2",
      modelValue: currEndDate.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currEndDate).value = $event)),
      lowerLimit: currStartDate.value,
      upperLimit: new Date(Date.now())
    }, null, 8, ["modelValue", "lowerLimit", "upperLimit"])
  ]))
}
}

})