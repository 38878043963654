import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ho-filter mt-2"
}
const _hoisted_2 = {
  key: 1,
  class: "ho-date-picker"
}

import { ref, Ref } from 'vue';
import ListBox from "../form/list-box.vue";
import IListBoxSelectionsType from "../form/interfaces/iListBoxSelectionsType";
import DateRange from "../form/date-range.vue";
import IDateRangeValues from "../form/interfaces/iDateRangeValues";
import IGridHeadCellConfig from './interfaces/iGridHeadCellConfig';


export default /*@__PURE__*/_defineComponent({
  __name: 'grid-head-cell-filter',
  props: {
	item: {
		type: Object as () => IGridHeadCellConfig,
	},
	filterActive: {
		type: Boolean,
		default: () => false
	}
},
  emits: ['onFiltersChange'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;
const filterSelection: Ref<IListBoxSelectionsType | null> = ref(null);
		
const handleListBoxSelect = (selection: IListBoxSelectionsType) => {
	filterSelection.value = selection;

	if (props.item?.filterOptions) {
		const selectedFilter = {
			field: props.item?.filterOptions.name,
			value: selection.value
		};

		emit('onFiltersChange', [selectedFilter]);
	}
}

const handleDateRangeChange = ({ startDate, endDate }: IDateRangeValues) => {
	emit('onFiltersChange', [
		{
			name: 'startDate',
			value: startDate
		},
		{
			name: 'endDate',
			value: endDate
		}
	]);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("th", null, [
    (__props.filterActive && __props.item && __props.item.filterOptions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.item.filterOptions.type === 'select')
            ? (_openBlock(), _createBlock(ListBox, {
                key: 0,
                class: "",
                label: '',
                selections: __props.item.filterOptions?.options,
                onOnListBoxSelect: handleListBoxSelect
              }, null, 8, ["selections"]))
            : _createCommentVNode("", true),
          (__props.item.filterOptions.type === 'dateRange')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(DateRange, { onOnChange: handleDateRangeChange })
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})