<template>
	<div :class="{'filters-condensed': filtersCondensed }">
		<div class="ho-filters-condensed flex">
			<!-- <button><ChevronDoubleDownIcon class="w-6" @click="toggleFIltersCondesed" /></button> -->
			<label>Показани фактури за:</label>
			<label 
				class="selection"
				v-for="filter in filterValuesLabels"
				:key="filter.field" 
				@click="toggleFIltersCondesed"
			>{{ filter.value }}</label>
		</div>
		<div class="ho-filters flex">
			<div class="flex flex-wrap justify-start ho-only-filters-container">
				<!-- <button><ChevronDoubleUpIcon class="w-6" @click="toggleFIltersCondesed" /></button> -->
				<div 
					class="ho-filter" 
					:class="{active:getIsActive(filter.name)}"
					:key="filter.name" 
					v-for="filter in filterFields"
				>
					<div v-if="filter.type === 'select'" class="ho-autoselect-filter ho-filter-item">
						<Autocomplete
							:placeholder="filter.placeholderText"
							:options="filter.options"
							:defaultValue="filter.initialValue || null"
							:canClear="filter.canClear"
							@onChange="handleAutoselectChange($event, filter.name)"
						/>
					</div>

					<div v-if="filter.type === 'dateRange'" class="flex items-center ho-filter-item filter-date-selector">
						<label class="ml-1"><CalendarIcon class="h-6 w-6"/></label>
						<month-year-selector :default-month="defaultDateRange?.month" :default-year="defaultDateRange?.year" @onChange="handleDateChange"/>
					</div>
				</div>
			</div>

			<div class="ho-filter ho-toggle-filter ho-filter-item transparent whitespace-nowrap">
				<toggle 
					class="mt-2"
					:label="localization.t('filters.with-cancelled')"
					name="cancelled"
					@onChange="handleCheckboxChange($event, 'includeCancelled')"
				/>
			</div>

			<div class="ho-filter btns mt-2 ml-8">
				<button class="btn px-8" v-bind:disabled="isLoading || disableFilterButton" @click="handleApplyFilters">{{localization.t('filters.filter')}}</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { format, getMonth, getYear } from "date-fns";
import MonthYearSelector from "../form/month-year-selector.vue";
import Autocomplete from '../form/autocomplete.vue';
import Toggle from '../form/toggle.vue';
import IFilterField from '@/interfaces/home/iFilterField';
import IListFilter from "@/interfaces/invoices/iListFilter";
import { getEndDateFromPeriod, getStartDateFromPeriod } from '@/utils/dateTime';
import { CalendarIcon } from '@heroicons/vue/outline';

const localization = useI18n();

const props = defineProps({
	filterFields: {
		type: Array as () => IFilterField[],
		default: () => []
	},
	filters: {
		type: Array as () => IListFilter[],
		default: () => []
	},
	loading: {
		type: Object,
		default: () => {
			return {
				export: false,
				grid: false,
				companies: false
			}
		}
	}
});
const emit = defineEmits(['onFiltersChange', 'onApplyFilters']);
const changedFilters: Ref<IListFilter[]> = ref([...props.filters]);
const filtersCondensed = ref(false);

const disableFilterButton = computed(() => {
	const companies = props.filterFields.find(f => f.name == 'company');
	if (companies && companies.options){
		return companies.options.length === 0;
	}

	return true;
});

const filterValuesLabels = computed(() => {
	const mappedFilterValues:IListFilter[] = [];
	changedFilters.value.forEach(f => {
		switch (f.field) {
			case 'ledger':
					f.value && mappedFilterValues.push({
					field: 'ledger',
					value: localization.t(`home.${f.value.toString()}`)
				});
				break;
			case 'startDate': {
				const monthLabel = localization.t(`month-year-selector.months.${
					format(new Date(f.value.toString()), 'LLLL').toLowerCase()
				}`);
				const yearLabel = format(new Date(f.value.toString()), 'yyyy');

				f.value && mappedFilterValues.push({
					field: 'startDate',
					value: `${monthLabel} ${yearLabel}`
				});
				break;
			}
			case 'company': {
				const selectedCompanyName = props.filterFields
					.find(f => f.name === 'company')?.options?.find(
						o => Number(o.value) === Number(f.value)
					)?.label;
				
				selectedCompanyName && mappedFilterValues.push({
					field: 'company',
					value: selectedCompanyName
				});
				break;
			}
			case 'includeCancelled':
				f.value === true && mappedFilterValues.push({
					field: 'includeCancelled',
					value: localization.t('filters.with-cancelled')
				});
				break;
			default:
				break;
		}
	});
	
	return mappedFilterValues;
});

const defaultDateRange = computed(() => {
	const defaultDate: Date = props.filters.find(f => f.field === 'startDate')?.value as Date;

	if (!defaultDate) return null;

	return {
		month: getMonth(defaultDate),
		year: getYear(defaultDate)
	};
});

const isLoading = computed(() => {
	return props.loading.export || props.loading.grid || props.loading.companies;
});

const getIsActive = (filterName: string) => {
	let filterValue;
	if (filterName === 'date') {
		const startDateVal = props.filters.find(f => f.field === 'startDate')?.value;
		const endDateVal = props.filters.find(f => f.field === 'endDate')?.value;

		filterValue = startDateVal || endDateVal;
	} else {
		filterValue = props.filters.find(f => f.field === filterName)?.value;
	}

	return filterValue && filterValue.toString().trim().length > 0;
};

const toggleFIltersCondesed = () => {
	filtersCondensed.value = !filtersCondensed.value;
};

const handleChangeFilters = (filters: IListFilter[]) => {
	(filters || []).forEach(filter => {
	
		const foundFilter = changedFilters.value.find(f => f.field === filter.field);
		if (foundFilter) {
			foundFilter.value = filter.value
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value
			}   

			changedFilters.value.push(newFilter);
		}
	});
}

const handleDateChange = (month: number, year: number) => {
	const startDate = getStartDateFromPeriod(month, year);
	const endDate = getEndDateFromPeriod(month, year);
	handleChangeFilters([
		{
			field: 'startDate',
			value: startDate
		},
		{
			field: 'endDate',
			value: endDate
		}
	]);
};

const handleAutoselectChange = (value: number, filterName: string) => {
	handleChangeFilters([
		{
			field: filterName,
			value
		}
	]);
}
const handleCheckboxChange = (value: boolean, filterName: string)=>{
	handleChangeFilters([
		{
			field: filterName,
			value
		}
	]);
}
const handleApplyFilters = () => {
	emit('onFiltersChange', changedFilters.value);
	emit('onApplyFilters');
}
</script>

<style lang="postcss" scoped>
.ho-filters { 
  margin-bottom: 16px; 
}

.filters-condensed { position: relative; z-index: 1;}

.filters-condensed .ho-filters { display: none; }

.ho-filters-condensed { display: none; } 
.filters-condensed .ho-filters-condensed { display: block; } 

.ho-filter { 
  background: rgba(255, 255, 255, 0.8);
  display: inline-block; min-width: 275px;
  margin-right: 8px; margin-bottom: 8px;
  cursor: pointer;
	
  @apply rounded
}

.ho-filters-condensed { font-size: 1.4rem; margin-bottom: 16px;}
.ho-filters-condensed label {
	display: inline-block;
	margin-right: 8px;
	padding-top: 4px; padding-bottom: 4px;

	@apply rounded
}

.ho-filters-condensed label.selection { 
	background-color: rgba(255, 255, 255, 0.6); 
	padding-left: 8px; padding-right: 8px;
	border-bottom: 2px solid #a9a9d0;
}

.ho-filter-item { margin: 8px; align-self: flex-start; }
.ho-filter-item.filter-date-selector { margin-top: 0px; margin-bottom: 0px; height: 100% }

.ho-filter.ho-toggle-filter { width: 200px; min-width: 200px; }

.ho-filter.transparent,
.ho-filter.transparent:hover { 
	background: transparent; 
	@apply pl-0 pr-0
}

.ho-filters .ho-autocomplete,
:deep(.ho-filter .ho-month-year-selector .ho-autocomplete) { 
	--ms-border-color: transparent; 
	--ms-ring-color: transparent; 
	--ms-ring-width: 0;
}

.ho-filter.btns { background: transparent; padding: 0px 0 0px; width: auto; min-width: auto; align-self: flex-start; }
.ho-filter.btns:hover { background: transparent; }
.ho-filter.btns .btn { height: 100%; margin: 0; }

.ho-filter:hover,
.ho-filter.active { background: rgba(255, 255, 255, 1); }

.ho-filter .ho-month-year-selector { width: auto }

.ho-filter .ho-month-year-selector { width: auto; height: 100%; }

:deep(.ho-filter .ho-month-year-selector .ho-selector-month),
:deep(.ho-filter .ho-month-year-selector .ho-selector-year) { height: 100%; }
:deep(.ho-filter .ho-month-year-selector .ho-autocomplete) { margin-top: 9px; }

:deep(.ho-filter .ho-month-year-selector .ho-selector-month) { border-right: 1px solid #eee; padding-right: 8px }

@media (max-width: 1265px) {
	.ho-filters { min-width: 980px; }
	.ho-filter { min-width: 120px; }
	.ho-filter.ho-toggle-filter { width: auto; min-width: auto; }
}

@media (max-width: 1265px) {
	.ho-filter.ho-toggle-filter { width: 160px; }
}

@media (max-width: 980px) {
	.ho-filters { transform: scale(0.9); position: relative; left: -42px; }
	.initial .ho-filters { transform: none; left: 0px; }
	.initial .ho-filters { min-width: auto; }
}

@media (max-width: 880px) {
	.ho-filters { transform: scale(0.8); position: relative; left: -92px; }
	.initial .ho-filters { transform: none; left: 0px; }
	.initial .ho-filters { min-width: auto; }
}
</style>