import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "list-box" }
const _hoisted_2 = { class: "relative flex items-center h-full" }
const _hoisted_3 = { class: "block w-auto truncate" }
const _hoisted_4 = { class: "flex items-center pointer-events-none" }
const _hoisted_5 = {
  key: 0,
  class: "absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
}

import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/i18n';
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import IListBoxSelectionsType from './interfaces/iListBoxSelectionsType';


export default /*@__PURE__*/_defineComponent({
  __name: 'list-box',
  props: {
  className: {
    type: String,
    defualt: ""
  },
  label: {
    type: String,
    default: i18n.global.t('list-box.select')
  },
  selections: {
    type: Array as () => IListBoxSelectionsType[] | undefined
  },
  multiple: {
    type: Boolean,
    default: () => false
  }
},
  emits: ['onListBoxSelect'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const props = __props 
const emit = __emit;
const currentSelections: Ref<IListBoxSelectionsType[]> = ref([]);

const onSelect = (selection: IListBoxSelectionsType) => {
  if (props.multiple) {
    if (props.selections && props.selections.length > 0) {
      const foundCurrSelectionIndex = currentSelections.value.findIndex(s => s.value === selection.value);
      if (foundCurrSelectionIndex > -1) {
        currentSelections.value.splice(foundCurrSelectionIndex, 1);
      } else {
        currentSelections.value.push(selection)
      }
      
      emit('onListBoxSelect', selection);
    }
  } else {
    currentSelections.value = [selection];
    emit('onListBoxSelect', selection);
  }
}

const selectionLabel = computed(() => {
  if (currentSelections.value && currentSelections.value.length >= 1) {
    return currentSelections.value.length > 1 
      ? `${currentSelections.value.length} ${localization.t('list-box.filters')}` 
      : `${currentSelections.value[0].label}`
  }

  return localization.t('list-box.no-selection');
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Listbox), null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (__props.label && __props.label.length > 0)
            ? (_openBlock(), _createBlock(_unref(ListboxLabel), { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.label) + ":", 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_unref(ListboxButton), { class: "flex items-center p-2 pl-3 text-left" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(selectionLabel.value), 1),
              _createElementVNode("span", _hoisted_4, [
                _createVNode(_unref(SelectorIcon), {
                  class: "w-5 h-5 text-gray-400",
                  "aria-hidden": "true"
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_Transition, {
            "leave-active-class": "transition duration-100 ease-in",
            "leave-from-class": "opacity-100",
            "leave-to-class": "opacity-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(ListboxOptions), { class: "absolute w-full py-1 mt-1 overflow-auto bg-white shadow-lg max-h-60 ring-black ring-opacity-5 focus:outline-none" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.selections, (selection) => {
                    return (_openBlock(), _createBlock(_unref(ListboxOption), {
                      key: selection.value,
                      value: selection.value,
                      as: "template",
                      onClick: ($event: any) => (onSelect(selection))
                    }, {
                      default: _withCtx(({ active, selected }) => [
                        _createElementVNode("li", {
                          class: _normalizeClass([ active ? 'text-amber-900 bg-amber-100' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-10 pr-4' ])
                        }, [
                          _createElementVNode("span", {
                            class: _normalizeClass([selected ? 'font-medium' : 'font-normal','block truncate'])
                          }, _toDisplayString(selection.label), 3),
                          selected
                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                _createVNode(_unref(CheckIcon), {
                                  class: "w-5 h-5",
                                  "aria-hidden": "true"
                                })
                              ]))
                            : _createCommentVNode("", true)
                        ], 2)
                      ]),
                      _: 2
                    }, 1032, ["value", "onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}
}

})