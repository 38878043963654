import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Teleport as _Teleport, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "ho-app" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header-part header-part-left" }
const _hoisted_4 = { class: "menu-label whitespace-nowrap -mr-2" }
const _hoisted_5 = { class: "font-bold" }
const _hoisted_6 = { class: "status-text" }
const _hoisted_7 = { class: "dropdown" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "w-8 h-8 text-center" }
const _hoisted_10 = { class: "mr-auto inline-block" }
const _hoisted_11 = { class: "header-part header-part-right" }
const _hoisted_12 = { class: "w-8 h-8 text-center" }
const _hoisted_13 = { class: "user menu-item menu-item-dropdown menu-item-right" }
const _hoisted_14 = { class: "mr-auto inline-block" }
const _hoisted_15 = { class: "dropdown min-w-96" }
const _hoisted_16 = { class: "w-8 h-8 text-center" }
const _hoisted_17 = { class: "w-8 h-8 text-center" }
const _hoisted_18 = { class: "w-8 h-8 text-center" }
const _hoisted_19 = { class: "user menu-item menu-item-dropdown menu-item-right" }
const _hoisted_20 = { class: "dropdown min-w-96" }

import { computed, onMounted, ref, Teleport, Ref, toRaw } from 'vue';
import { useI18n } from 'vue-i18n';
import router from "../router";
import DeclarantDataDialog from "@/components/profile/declarant-data-dialog.vue"
import { changeMyPassword, logout, syncSession } from "@/services/authService";
import { getAll as getAllCompanies } from '@/services/companiesApi';
import ConnectionsDialog from "@/components/app/connections-dialog.vue"
import { editUserConnections, getActiveConnection, switchConnection, updateMyProfile } from "@/services/userApi";
import { UserCircleIcon, PencilAltIcon, LogoutIcon, CogIcon, DocumentDuplicateIcon, AdjustmentsIcon } from '@heroicons/vue/outline'
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import IProfile from '@/interfaces/users/iProfile';
import IUser from '@/interfaces/users/iUser';
import { ApiError } from '@/types/errorTypes';
import { createToast } from 'mosha-vue-toastify';
import { TOAST_TYPES } from '@/constants/toastr';
import IConnection from '@/interfaces/users/iConnection';
import UserDataDialog from '@/components/profile/user-data-dialog.vue';
import LocaleSwitch from '@/components/app/locale-switch.vue';
import LanguageFlags from '@/components/svg/language-flags.vue';
import LoadingSpinner from "@/components/loading-spinner.vue";
import AppTeleportContainer from "@/components/app-teleport-container/ho-app-teleport-container.vue";
import { APP_TELEPORT_CONTAINER_ID } from "@/constants/app";
import ImportExternalInvoices from "@/components/home/import-external-invoices.vue";
import IConvertDeclarationResult from '@/interfaces/home/iConvertDeclarationResult';
import IDeclarationData from '@/interfaces/home/iDeclarationData';
import { importData } from '@/services/invoicesApi';
import ICompany from '@/interfaces/companies/iCompany';
import IDeclarantExtended from '@/interfaces/users/iDeclarantExtended';
type LoadingFields = 'user' | 'connection' | 'connect' | 'password' | 'import' | 'companies';


export default /*@__PURE__*/_defineComponent({
  __name: 'Home',
  props: {
	user: {
		type: Object as () => IUser
	}
},
  emits: ['onReloadUser'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const props = __props;

const emit = __emit;

const loading = ref({
  user: false,
  password: false,
  connection: false,
  connect: false,
  import: false,
  companies: false
});

const companies: Ref<ICompany[]> = ref([]);
const companiesLoaded = ref(false)
const declarantDataDialogOpen = ref(false);
const myProfileDialogOpen = ref(false);
const connectionsDialogOpen = ref(false);

const importModalOpen = ref(false);
const importResult = ref<IConvertDeclarationResult>();
const firstFilterTriggered = ref(false);

const activeConnection = computed(() => {
  const activeConnection = props.user?.connections && props.user?.connections.find(c => c.active === true);
  return activeConnection;
});

const init = async () => {
	if (!props.user?.connections || props.user?.connections.length === 0) {
		connectionsDialogOpen.value = true;
		return;
	}

	const activeConnection = getActiveConnection(props.user?.connections || []);

	if (!activeConnection) {
		connectionsDialogOpen.value = true;
		return;
	}

	try {
		await executeAsync('connect', async () => {
			await syncSession(activeConnection.db);
			await getCompanies();
		});
	} catch (error) {
		connectionsDialogOpen.value = true;
	}
};

onMounted(init);

const fullName = computed(() => {
  let fullNameLabel = props.user?.userName;
  if (props.user?.profileData) {
    if (props.user?.profileData.firstName) {
      fullNameLabel = `${props.user?.profileData.firstName}`;
    }

    if (props.user?.profileData.lastName) {
      fullNameLabel = `${fullNameLabel} ${props.user?.profileData.lastName}`;
    }
  }

  return fullNameLabel;
});

const profileData = computed(() => {
	return toRaw({ ...(props.user || {}).profileData } as IProfile & IDeclarantExtended);
});

const getCompanies = async () => {
	await executeAsync('companies', async () => {
		const companiesResult = await getAllCompanies();
    companies.value = companiesResult;
		companiesLoaded.value = true;
	});
};

const showDeclarantDataDialog = async () => {
  declarantDataDialogOpen.value= true;
}
const showConnectionsDialog = () => {
  connectionsDialogOpen.value = true;
}

const showMyProfileDialog = () => {
  myProfileDialogOpen.value = true;
}

const handleDeclarantDataDialogClose = async (profileData: IProfile) => {
  if (profileData) {
    await executeAsync('user', async () => {
      await updateMyProfile(profileData);
      emit('onReloadUser');
			declarantDataDialogOpen.value = false;
    });
  } else {
    declarantDataDialogOpen.value = false;
  }
}

const handleChangePasswordDialogClose = async (oldPassword: string, newPassword: string) => {
  if(oldPassword && newPassword){
    await executeAsync('password', async () => {
      await changeMyPassword(oldPassword, newPassword);
      myProfileDialogOpen.value = false;
    });
  } else {
    myProfileDialogOpen.value = false;
  }
}

const handleLogout = () => {
  logout();
  location.replace('/login');
}

const handleSelectConnection = async (connection: IConnection) => {
  try {
    await executeAsync('connect', async () => {
      await switchConnection(connection.db);
      location.reload();
    });
  } catch (error) {
    connectionsDialogOpen.value = true;
  }
}

const handleConnectionsDialogClose = async (connection?: IConnection) => {
  if (connection) {
    try {
      await executeAsync('connect', async () => {
        await switchConnection(connection.db);
        location.reload();
        connectionsDialogOpen.value = false;
      });
    } catch (error) {
      connectionsDialogOpen.value = true;
    }
  } else {
    connectionsDialogOpen.value = false;
  }
}

const handleEditConnectionsClose = async (connections?: IConnection[], reconnect?: boolean, onSuccess?: () => void) => {
  if (connections) {
    await executeAsync('connection', async () => {
      await editUserConnections(connections);
      emit('onReloadUser');
      
      if (reconnect) {
        const activeConnection = getActiveConnection(connections || []);

        if (!activeConnection) {
          connectionsDialogOpen.value = true;
          return;
        }
        
        const connectionDB = activeConnection?.db;
        if (connectionDB) {
          await executeAsync('connect', async () => {
            await switchConnection(connectionDB);
            onSuccess && onSuccess();
            connectionsDialogOpen.value = false;
          });
        }
      } else {
        onSuccess && onSuccess();
      }
    });
  }
}

const handleImportModalOpen = () => {
	importModalOpen.value = true;
}

const handleImportModalClose = async (uploadFile: File | null, declarationData: IDeclarationData) => {
	if (uploadFile) {
		const formData = new FormData();
		formData.append('file', uploadFile);
		try {
			const downloadBLobResult = await executeAsync('import', async () => {
				return await importData(formData, declarationData);
			});
			importResult.value = downloadBLobResult;
		} catch (error) {
      importModalOpen.value = !importModalOpen.value;
		}
	} else {
		importModalOpen.value = !importModalOpen.value;
	}
}

const handleFirstFilterTriggered = () => {
  if (!firstFilterTriggered.value) {
    firstFilterTriggered.value = true;
  }
}

const goToAdministration = () => router.push('/admin/users');

const executeAsync = async (loadingField: LoadingFields, exec: any, showToast = true) => {
  loading.value[loadingField] = true;

  try {
    const result = await exec();
    loading.value[loadingField] = false;
    return result;
  } catch (error) {
    const err = error as ApiError;

		const errMessage = loadingField === 'connect' 
			? localization.t('errors.cannot-connect-server', { status: err.statusCode, statusText: err.message})
			: err.message;

		showToast && createToast(errMessage, TOAST_TYPES.ERROR);

		if (process.env.NODE_ENV !== 'production' && showToast && err.innerMessage) {
			createToast(err.innerMessage, TOAST_TYPES.ERROR);
		}

		throw error;
  } finally {
    loading.value[loadingField] = false;
  }
}

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["wrapper", { initial: !firstFilterTriggered.value }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_unref(localization).t('app.odoo-connection')), 1)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["status menu-item menu-item-left menu-item-dropdown", {
            connected: activeConnection.value,
            disconnected: !activeConnection.value
          }])
          }, [
            _createElementVNode("span", _hoisted_6, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-8 w-8 inline-block" }, [
                _createElementVNode("span", { class: "dot" })
              ], -1)),
              _createTextVNode(" " + _toDisplayString((activeConnection.value && (activeConnection.value.alias || activeConnection.value.db)) || _unref(localization).t('app.not-connected')), 1)
            ]),
            _createElementVNode("span", null, [
              _createVNode(_unref(ChevronUpIcon), { class: "dropdown-icon-up w-6" }),
              _createVNode(_unref(ChevronDownIcon), { class: "dropdown-icon-down w-6" })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((__props.user?.connections || []), (connection) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: connection.db,
                    onClick: ($event: any) => (handleSelectConnection(connection))
                  }, [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "w-8 h-8 text-center" }, null, -1)),
                    _createElementVNode("label", null, _toDisplayString(connection.alias || connection.db), 1)
                  ], 8, _hoisted_8))
                }), 128)),
                _createElementVNode("li", { onClick: showConnectionsDialog }, [
                  _createElementVNode("span", _hoisted_9, [
                    _createVNode(_unref(CogIcon), { class: "w-6 h-6" })
                  ]),
                  _createElementVNode("label", null, _toDisplayString(_unref(localization).t('app.configs')), 1)
                ])
              ])
            ])
          ], 2),
          (firstFilterTriggered.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "user menu-item menu-item-dropdown menu-item-left",
                onClick: handleImportModalOpen
              }, [
                _createElementVNode("span", null, [
                  _createVNode(_unref(DocumentDuplicateIcon), { class: "h-8 w-8" })
                ]),
                _createElementVNode("label", _hoisted_10, _toDisplayString(_unref(localization).t('home.import-convert')) + " " + _toDisplayString(_unref(localization).t('home.vat-file')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          (__props.user?.role === 'admin')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "user menu-item menu-item-right menu-item-dropdown",
                onClick: goToAdministration
              }, [
                _createElementVNode("span", _hoisted_12, [
                  _createVNode(_unref(AdjustmentsIcon), { class: "w-6 h-6" })
                ]),
                _createElementVNode("label", null, _toDisplayString(_unref(localization).t('app.administration')), 1)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, [
              _createVNode(_unref(UserCircleIcon), { class: "h-8 w-8" })
            ]),
            _createElementVNode("label", _hoisted_14, _toDisplayString(fullName.value), 1),
            _createVNode(_unref(ChevronUpIcon), { class: "dropdown-icon-up w-6" }),
            _createVNode(_unref(ChevronDownIcon), { class: "dropdown-icon-down w-6" }),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", { onClick: showMyProfileDialog }, [
                  _createElementVNode("span", _hoisted_16, [
                    _createVNode(_unref(PencilAltIcon), { class: "w-6 h-6" })
                  ]),
                  _createElementVNode("label", null, _toDisplayString(_unref(localization).t('app.my-profile')), 1)
                ]),
                _createElementVNode("li", { onClick: showDeclarantDataDialog }, [
                  _createElementVNode("span", _hoisted_17, [
                    _createVNode(_unref(PencilAltIcon), { class: "w-6 h-6" })
                  ]),
                  _createElementVNode("label", null, _toDisplayString(_unref(localization).t('app.declarant-data')), 1)
                ]),
                _createElementVNode("li", { onClick: handleLogout }, [
                  _createElementVNode("span", _hoisted_18, [
                    _createVNode(_unref(LogoutIcon), { class: "w-6 h-6" })
                  ]),
                  _createElementVNode("label", null, _toDisplayString(_unref(localization).t('app.logout')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", null, [
              _createVNode(LanguageFlags, {
                class: "h-8 w-8 mr-2 rounded-full",
                selectedLanguage: _unref(localization).locale.value
              }, null, 8, ["selectedLanguage"])
            ]),
            _createVNode(_unref(ChevronUpIcon), { class: "dropdown-icon-up w-6" }),
            _createVNode(_unref(ChevronDownIcon), { class: "dropdown-icon-down w-6" }),
            _createElementVNode("div", _hoisted_20, [
              _createVNode(LocaleSwitch)
            ])
          ])
        ])
      ]),
      (declarantDataDialogOpen.value)
        ? (_openBlock(), _createBlock(DeclarantDataDialog, {
            key: 0,
            profileData: __props.user?.profileData,
            onOnUpdateDeclarantClose: handleDeclarantDataDialogClose
          }, null, 8, ["profileData"]))
        : _createCommentVNode("", true),
      (myProfileDialogOpen.value)
        ? (_openBlock(), _createBlock(UserDataDialog, {
            key: 1,
            userData: __props.user,
            loading: loading.value.password,
            onOnChangePasswordClose: handleChangePasswordDialogClose
          }, null, 8, ["userData", "loading"]))
        : _createCommentVNode("", true),
      (connectionsDialogOpen.value)
        ? (_openBlock(), _createBlock(ConnectionsDialog, {
            key: 2,
            loading: loading.value,
            connections: [...__props.user?.connections || []],
            onOnConnectionsDialogClose: handleConnectionsDialogClose,
            onOnEditConnectionsClose: handleEditConnectionsClose
          }, null, 8, ["loading", "connections"]))
        : _createCommentVNode("", true),
      (importModalOpen.value)
        ? (_openBlock(), _createBlock(ImportExternalInvoices, {
            key: 3,
            declarantData: profileData.value,
            onOnUploadModalClose: handleImportModalClose,
            importResult: importResult.value,
            loading: loading.value.import,
            companies: companies.value
          }, null, 8, ["declarantData", "importResult", "loading", "companies"]))
        : _createCommentVNode("", true),
      (
        loading.value.connect
        || loading.value.user
        || loading.value.companies
      )
        ? (_openBlock(), _createBlock(LoadingSpinner, { key: 4 }))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_Teleport, { to: _unref(APP_TELEPORT_CONTAINER_ID) }, null, 8, ["to"])),
      _createVNode(_component_router_view, {
        class: "ho-app",
        user: __props.user,
        connection: activeConnection.value,
        companies: companies.value,
        onOnImportModalOpen: handleImportModalOpen,
        onOnOpenConnections: showConnectionsDialog,
        onOnFirstFilterTriggered: handleFirstFilterTriggered
      }, null, 8, ["user", "connection", "companies"])
    ], 2)
  ]))
}
}

})