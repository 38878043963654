import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ui-test-grid-anim-row"
}
const _hoisted_2 = {
  key: 0,
  class: "checkbox ho-cell-static w-18 pl-7 pt-5 ui-test-grid-checkbox-cell"
}
const _hoisted_3 = ["value"]
const _hoisted_4 = {
  key: 1,
  class: "ho-cell-static w-14 ui-test-grid-filterable-cell"
}

import { ref, watch } from 'vue';
import { formatToUi } from "@/utils/dateTime";
import IGridHeadCellConfig from './interfaces/iGridHeadCellConfig';
import { getCellWidthStyle } from './shared/gridUtils';


export default /*@__PURE__*/_defineComponent({
  __name: 'grid-row',
  props: {
	data: [Object as () => any, Number],
	columnsConfig: {
		type: Array as () => IGridHeadCellConfig[]
	},
	filterableHeader: {
		type: Boolean,
		default: () => false
	},
	selectable: {
		type: Boolean,
		default: () => false
	},
	checkedRows: {
		type: Array,
		default: () => []
	},
	loading: {
		type: Boolean,
		default: () => false
	}
},
  emits: ['onCheckboxChange'],
  setup(__props, { emit: __emit }) {

const requiredFileds = ['customerVat', 'customerCountry', 'nraDocType', 'number'];

const props = __props;
const emit = __emit;

const checkedRowsVar = ref(props.checkedRows);
watch(() => props.checkedRows.length, () => {
	if (props.selectable) {
		checkedRowsVar.value = [...props.checkedRows];
	}
}, {
	immediate: true
});
const getParsedField = (field: Date | number | string | null) => {
	if (field instanceof Date) {
		return formatToUi(field);
	} else if (typeof field === 'number'){
		const number = new Intl.NumberFormat('en-US', 
			{
				minimumFractionDigits: 2, 
				maximumFractionDigits: 2
			}).format(field);

		return number;
	}

	return field;
};

const getBodyCellWidthStyle = (key: string) => {
	const width = (props.columnsConfig || []).find(c => c.key === key)?.width
	const minWidth = (props.columnsConfig || []).find(c => c.key === key)?.minWidth
	return getCellWidthStyle(width, minWidth)
}

const getAnimDelay = (index: number) => {
	return `animation-delay: 0.${index}s`;
}
const getCellClasses = (key: string, value: Date | string | number | null)=>{
	let result = "";
	
	if(requiredFileds.includes(key) && !value){
		result = 'error-cell'
	}

	const columnConfig = props.columnsConfig?.find(conf => conf.key === key);
	if (columnConfig && columnConfig.class) {
		result = `${result} ${columnConfig.class}`;
	}

	return result;
}

const getBodyRowErrorClass = (rowData: any)=>{
	let result = "";
	for (const [key,value] of Object.entries(rowData)) {
		if(requiredFileds.includes(key) && !value){
			result = 'error-row'
		}
	}
	return result;
}	

const handleCheckboxChange = () =>{
	if(props.selectable){
		const data = props.data;
		emit('onCheckboxChange', data.number);
	}
}

const dummyData = {
	nraDocType: "",
	number: "",
	invoiceDate: new Date(),
	date: new Date(),
	customerVat : "",
	customer: "",
	customerCountry: "",
	taxBase: 0,
	tax: 0,
	status: "",
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.loading)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(dummyData, (v, k, i) => {
            return _createElementVNode("td", {
              class: "ui-test-grid-anim-cell",
              key: k,
              style: _normalizeStyle(getBodyCellWidthStyle(k))
            }, [
              _createElementVNode("span", {
                class: "load-anim ui-test-grid-anim-span",
                style: _normalizeStyle(getAnimDelay(i))
              }, null, 4)
            ], 4)
          }), 64))
        ]))
      : _createCommentVNode("", true),
    (!__props.loading && __props.data && (typeof __props.data !== 'number'))
      ? (_openBlock(), _createElementBlock("tr", {
          key: 1,
          class: _normalizeClass(["ui-test-grid-data-row", getBodyRowErrorClass(__props.data)]),
          onClick: _withModifiers(handleCheckboxChange, ["stop"])
        }, [
          (__props.selectable)
            ? (_openBlock(), _createElementBlock("td", _hoisted_2, [
                _withDirectives(_createElementVNode("input", {
                  class: "ui-test-grid-checkbox-input",
                  type: "checkbox",
                  value: __props.data.number,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((checkedRowsVar).value = $event)),
                  onClick: _withModifiers(handleCheckboxChange, ["stop"])
                }, null, 8, _hoisted_3), [
                  [_vModelCheckbox, checkedRowsVar.value]
                ])
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, (v, k) => {
            return (_openBlock(), _createElementBlock("td", {
              class: _normalizeClass(["ui-test-grid-data-cell", getCellClasses(k, v)]),
              key: k,
              style: _normalizeStyle(getBodyCellWidthStyle(k))
            }, [
              _createElementVNode("span", null, _toDisplayString(getParsedField(v)), 1)
            ], 6))
          }), 128)),
          (__props.filterableHeader)
            ? (_openBlock(), _createElementBlock("td", _hoisted_4))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})