import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "ho-filters-condensed flex" }
const _hoisted_2 = { class: "ho-filters flex" }
const _hoisted_3 = { class: "flex flex-wrap justify-start ho-only-filters-container" }
const _hoisted_4 = {
  key: 0,
  class: "ho-autoselect-filter ho-filter-item"
}
const _hoisted_5 = {
  key: 1,
  class: "flex items-center ho-filter-item filter-date-selector"
}
const _hoisted_6 = { class: "ml-1" }
const _hoisted_7 = { class: "ho-filter ho-toggle-filter ho-filter-item transparent whitespace-nowrap" }
const _hoisted_8 = { class: "ho-filter btns mt-2 ml-8" }
const _hoisted_9 = ["disabled"]

import { computed, ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { format, getMonth, getYear } from "date-fns";
import MonthYearSelector from "../form/month-year-selector.vue";
import Autocomplete from '../form/autocomplete.vue';
import Toggle from '../form/toggle.vue';
import IFilterField from '@/interfaces/home/iFilterField';
import IListFilter from "@/interfaces/invoices/iListFilter";
import { getEndDateFromPeriod, getStartDateFromPeriod } from '@/utils/dateTime';
import { CalendarIcon } from '@heroicons/vue/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'filters',
  props: {
	filterFields: {
		type: Array as () => IFilterField[],
		default: () => []
	},
	filters: {
		type: Array as () => IListFilter[],
		default: () => []
	},
	loading: {
		type: Object,
		default: () => {
			return {
				export: false,
				grid: false,
				companies: false
			}
		}
	}
},
  emits: ['onFiltersChange', 'onApplyFilters'],
  setup(__props, { emit: __emit }) {

const localization = useI18n();

const props = __props;
const emit = __emit;
const changedFilters: Ref<IListFilter[]> = ref([...props.filters]);
const filtersCondensed = ref(false);

const disableFilterButton = computed(() => {
	const companies = props.filterFields.find(f => f.name == 'company');
	if (companies && companies.options){
		return companies.options.length === 0;
	}

	return true;
});

const filterValuesLabels = computed(() => {
	const mappedFilterValues:IListFilter[] = [];
	changedFilters.value.forEach(f => {
		switch (f.field) {
			case 'ledger':
					f.value && mappedFilterValues.push({
					field: 'ledger',
					value: localization.t(`home.${f.value.toString()}`)
				});
				break;
			case 'startDate': {
				const monthLabel = localization.t(`month-year-selector.months.${
					format(new Date(f.value.toString()), 'LLLL').toLowerCase()
				}`);
				const yearLabel = format(new Date(f.value.toString()), 'yyyy');

				f.value && mappedFilterValues.push({
					field: 'startDate',
					value: `${monthLabel} ${yearLabel}`
				});
				break;
			}
			case 'company': {
				const selectedCompanyName = props.filterFields
					.find(f => f.name === 'company')?.options?.find(
						o => Number(o.value) === Number(f.value)
					)?.label;
				
				selectedCompanyName && mappedFilterValues.push({
					field: 'company',
					value: selectedCompanyName
				});
				break;
			}
			case 'includeCancelled':
				f.value === true && mappedFilterValues.push({
					field: 'includeCancelled',
					value: localization.t('filters.with-cancelled')
				});
				break;
			default:
				break;
		}
	});
	
	return mappedFilterValues;
});

const defaultDateRange = computed(() => {
	const defaultDate: Date = props.filters.find(f => f.field === 'startDate')?.value as Date;

	if (!defaultDate) return null;

	return {
		month: getMonth(defaultDate),
		year: getYear(defaultDate)
	};
});

const isLoading = computed(() => {
	return props.loading.export || props.loading.grid || props.loading.companies;
});

const getIsActive = (filterName: string) => {
	let filterValue;
	if (filterName === 'date') {
		const startDateVal = props.filters.find(f => f.field === 'startDate')?.value;
		const endDateVal = props.filters.find(f => f.field === 'endDate')?.value;

		filterValue = startDateVal || endDateVal;
	} else {
		filterValue = props.filters.find(f => f.field === filterName)?.value;
	}

	return filterValue && filterValue.toString().trim().length > 0;
};

const toggleFIltersCondesed = () => {
	filtersCondensed.value = !filtersCondensed.value;
};

const handleChangeFilters = (filters: IListFilter[]) => {
	(filters || []).forEach(filter => {
	
		const foundFilter = changedFilters.value.find(f => f.field === filter.field);
		if (foundFilter) {
			foundFilter.value = filter.value
		} else {
			const newFilter: IListFilter = {
				field: filter.field,
				value: filter.value
			}   

			changedFilters.value.push(newFilter);
		}
	});
}

const handleDateChange = (month: number, year: number) => {
	const startDate = getStartDateFromPeriod(month, year);
	const endDate = getEndDateFromPeriod(month, year);
	handleChangeFilters([
		{
			field: 'startDate',
			value: startDate
		},
		{
			field: 'endDate',
			value: endDate
		}
	]);
};

const handleAutoselectChange = (value: number, filterName: string) => {
	handleChangeFilters([
		{
			field: filterName,
			value
		}
	]);
}
const handleCheckboxChange = (value: boolean, filterName: string)=>{
	handleChangeFilters([
		{
			field: filterName,
			value
		}
	]);
}
const handleApplyFilters = () => {
	emit('onFiltersChange', changedFilters.value);
	emit('onApplyFilters');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'filters-condensed': filtersCondensed.value })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("label", null, "Показани фактури за:", -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterValuesLabels.value, (filter) => {
        return (_openBlock(), _createElementBlock("label", {
          class: "selection",
          key: filter.field,
          onClick: toggleFIltersCondesed
        }, _toDisplayString(filter.value), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.filterFields, (filter) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["ho-filter", {active:getIsActive(filter.name)}]),
            key: filter.name
          }, [
            (filter.type === 'select')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(Autocomplete, {
                    placeholder: filter.placeholderText,
                    options: filter.options,
                    defaultValue: filter.initialValue || null,
                    canClear: filter.canClear,
                    onOnChange: ($event: any) => (handleAutoselectChange($event, filter.name))
                  }, null, 8, ["placeholder", "options", "defaultValue", "canClear", "onOnChange"])
                ]))
              : _createCommentVNode("", true),
            (filter.type === 'dateRange')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("label", _hoisted_6, [
                    _createVNode(_unref(CalendarIcon), { class: "h-6 w-6" })
                  ]),
                  _createVNode(MonthYearSelector, {
                    "default-month": defaultDateRange.value?.month,
                    "default-year": defaultDateRange.value?.year,
                    onOnChange: handleDateChange
                  }, null, 8, ["default-month", "default-year"])
                ]))
              : _createCommentVNode("", true)
          ], 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(Toggle, {
          class: "mt-2",
          label: _unref(localization).t('filters.with-cancelled'),
          name: "cancelled",
          onOnChange: _cache[0] || (_cache[0] = ($event: any) => (handleCheckboxChange($event, 'includeCancelled')))
        }, null, 8, ["label"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("button", {
          class: "btn px-8",
          disabled: isLoading.value || disableFilterButton.value,
          onClick: handleApplyFilters
        }, _toDisplayString(_unref(localization).t('filters.filter')), 9, _hoisted_9)
      ])
    ])
  ], 2))
}
}

})